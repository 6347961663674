:root {
  --baby-blue: #81defa;
  /* --primary: #14bae4; */
  /* --primaryRGB: 20, 186, 228; */
  /* --secondary: #0b1f5b;
  --secondaryRGB: 11, 31, 91; */
  /* animation: fadeIn 1s; */

  --background-color: #1d2125;
  --light-border: #a6c5e229;
  --lighter-border: #a6c5e259;
  --text-color-subtle: #9FADBC;
  --text-color-subtler: #9dabbbbd;
  --text-color-subtlerer: #9dabbb81;
  --primary-color: #00A3BF;
  --primary-color-rgb: 0, 163, 191;
  --primary-color-subtle: #58cce0d0;
  --secondary-color: #172B4D;
  --secondary-color-rgb: 23, 43, 77;
  --text-color-danger: #ff2a2acb;
  --text-color-danger-bg: #ff64647e;
  --accent-color: #00BCD4;
  --accent-color-rgb: 0, 188, 212;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  background-color: var(--background-color);
  color: white;
  overflow-x: hidden;
  animation: fadeInBlur 1s;
}

@keyframes fadeIn {
  from {
    filter: brightness(0.7)
  }
  to {
    filter: brightness(1)
  }
}

@keyframes fadeInBlur {
  from {
    filter: blur(5px);
    opacity: 0;
  }
  to {
    filter: blur(0px);
    opacity: 1;
  }
}

.loadingText {
  font-size: 1.5rem;
  color: var(--text-color-subtle);
}

.loadingSpinner {
  width: 1rem;
  height: 1rem;
  border: 0.2rem solid var(--accent-color);
  border-top: 0.2rem solid var(--light-border);
  border-radius: 50%;
  animation: spin 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.headerBar {
  width: calc(100% - 2rem);
  padding: 0 2rem;
  height: 4rem;
  border-bottom: 1px solid var(--light-border);
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--background-color);
  z-index: 1000;
}

.headerVerticalSpacer {
  height: 4rem;
}

.headerTitle {
  color: white;
  font-size: 1.5rem;
  padding: 1rem;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 0.1rem;
}

.headerSpacer {
  width: 2px;
  min-height: 2rem;
  background-color: var(--light-border);
}

.horizontalSpacer {
  min-width: 1rem;
}

.homeTitle {
  color: white;
  font-size: 3rem;
  font-weight: 500;
  margin: 1rem 0 2rem 0;
  text-align: center;
  width: calc(100% - 2rem);
}

.homeSection {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.homeSubtitle {
  color: white;
  font-size: 2.7rem;
  font-weight: 900;
  font-weight: 500;
  letter-spacing: 0.05rem;
}

.homeSubtext {
  max-width: 60%;
  color: var(--text-color-subtle);
  text-align: left;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.09rem;
}

.homeSpacer {
  width: 7rem;
  border: 3px solid var(--accent-color);
  border-radius: 1rem;
  margin: 2rem 0 1.3rem 0;
  transition: width 1s;
}

.verticalSpacer {
  min-height: 1rem;
}

.verticalSpacerLarge {
  height: 5rem;
}

.modularButton {
  all: unset;
  color: var(--text-color-subtle);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: background-color 0.2s;
  font-weight: 600;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  border: 1px solid transparent;
  transition: all 0.2s ease-out;
  white-space: nowrap;
}

.modularButton:not(.modularButtonActive):hover {
  background-color: var(--light-border);
  cursor: pointer;
}

.modularButton:not(.modularButtonActive):active {
  background-color: var(--lighter-border);
}

.modularButtonBorder {
  border: 1px solid var(--lighter-border);
}

.modularButton:not(.modularButtonActive):focus {
  background-color: var(--lighter-border);
}

.modularButton.modularButtonDisabled:hover {
  cursor: unset;
  background-color: transparent;
}

.modularButtonWide {
  padding: 0.5rem 2rem;
}

.modularButtonNarrow {
  padding: 0.5rem 0.5rem;
}

.modularButtonNoGap {
  gap: 0.5rem;
}

.modularButtonActive {
  /* color: var(--primary-color); */
  color: white;
  position: relative;
}

.modularButtonActive::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 15%;
  width: 70%;
  height: 2px; 
  background-color: var(--accent-color)
}

.modularButtonGlowIconOnHover:hover {
  filter: brightness(99);
}

.loginRegisterSwitchText {
  color: var(--text-color-subtle);
  font-size: 1.2rem;
  cursor: pointer;
  transition: color 0.2s;
}

.loginRegisterSwitchText:hover {
  color: white;
}


#root {
  height: 100%;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 1000;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.registerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33%;
}


.registerTitle {
  font-size: 2rem;    
  font-weight: 500;
  margin: 1rem 0 0.5rem 0;
  text-align: center;
  width: calc(100% - 2rem);
}

.registerSubtitle {
    font-size: 1.1rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
}

.registerSubtitleLink {
    color: var(--primary);
    cursor: pointer;
}

.loginRegisterBtn {
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 10rem;
  min-width: 10rem;
  padding: 1rem 1.5rem;
  font-size: 1.2rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  outline: none;
  cursor: pointer;
}

.loginWithGoogleBtn {
    border-radius: 10rem;
    padding: 1rem 1.5rem;
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    background-color: white;
    font-weight: 500;
    color: var(--primary);
    border: 2px solid var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transform: translateY(-0.5rem);
}

.registerInput {
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0;
    padding: 0.7rem;
    min-width: calc(100% - 1.4rem);
    border-radius: 0.5rem;
    background-color: var(--lighter-border);
    outline: none;
    border: none;
    color: rgb(235, 241, 243);
}


.registerInput::placeholder {
  color: var(--text-color-subtle);
  opacity: 0.5;
}

.registerInput:focus {
  border-bottom: 1px solid var(--primary);
}

.registerInputLbl {
    font-size: 1.3rem;
    font-weight: 400;
    color: var(--text-color-subtle);
    margin: 0.8rem 0 0.5rem 0;
    text-align: left;
    width: 100%;
}

.registerInputWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0;
    padding: 0;
}

.inputErrorLbl {
    all:unset;
    align-self: center;
    color: rgb(255, 0, 0);
    font-size: 1rem;
    font-weight: 400;
    background-color: rgba(255, 42, 42, 0.233);
    border-radius: 100px;
    overflow: hidden;
    max-height: 0px;
    padding: 0rem;
    transition: 0.5s;
}

.inputErrorLbl.show {
  max-height: 50px;
  padding: 0.1rem 0.7rem 0.2rem 0.7rem;
  margin-top: 0.5rem;
  transition: 0.5s;
}

.orText {
  color: var(--text-color-subtle);
  display: flex;
  align-items: center;
  justify-content: center;
}


.showPasswordIcon {
  position: absolute;
  right: 0.7rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
}

.calendarWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
}

.calendarDay {
  width: 10%;
  height: 4rem;
  border: 1px solid var(--secondary);
}

.setupWizard {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.loginSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.hostNameInput {
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  background-color: transparent;
  border: 1px solid var(--text-color-subtlerer);
  font-size: 1.2rem;
  color: var(--text-color-subtle);
}

.dashboardSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 4rem);
  animation: fadeInBlur 1s;
}

.settingsSection {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  /* max-height: calc(100vh - 4rem); */
}

.Sidebar {
  width: 20%;
  min-width: 21rem;
  min-height: calc(100vh - 4rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-right: 1px solid var(--light-border);
  position: sticky;
  top: 4rem;
  left: 0;
  transition: all 0.3s ease;
  margin-right: auto;
  /* animation: fadeIn 1s; */
}

.Sidebar:hover {
  border-right: 1px solid var(--text-color-subtler);
}

.sidebarCloseBtn {
  padding: 0.2rem;
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  top: 2rem;
  right: 0;
  cursor: pointer;
  border-radius: 100%;
  aspect-ratio: 1/1;
  border: 1px solid var(--text-color-subtler);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(50%);
  background-color: var(--background-color);
  transition: all 0.3s ease;
}

.sidebarCloseBtn:hover {
  border: 1px solid var(--text-color-subtler);
  transform: translateX(50%) scale(1.1);
}

.Sidebar:hover .sidebarCloseBtn {
  opacity: 1;
}

.sidebarTitle {
  color: var(--text-color-subtle);
  font-size: 1.7rem;
  text-align: left;
  width: calc(100% - 2rem);
  margin: 2rem 0 1rem 0;
}

.sidebarComputer {
  width: calc(100% - 4rem);
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: 1px solid var(--light-border);
  cursor: pointer;
  gap: 1rem;
  margin-bottom: 1.2rem;
  position: relative;
}

.sidebarComputerEditIcon {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  cursor: pointer;
  color: var(--text-color-subtle);
}

.sidebarComputerName {
  width: 100%;
  text-align: left;
  font-size: 1.5rem;
  color: var(--text-color-subtle);
}

.sidebarComputerId {
  font-size: 0.7rem;
  color: var(--text-color-subtlerer);
  width: 100%;
  text-align: right;
}

.dashboardMain {
  width: 100%;
  min-width: 40rem;
  height: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.3s ease;
  /* position: relative; */
  margin-right: auto;
}

.width50 {
  width: 50%;
}

.dashboardExpandBtn {
  opacity: 0.5;
  transition: opacity 0.3s ease;
  margin-bottom: auto;
}

.dashboardExpandBtn:hover {
  opacity: 1;
}

.logsContainerTitle {
  width: 100%;
  text-align: left;
  font-size: 1.7rem;
  letter-spacing: 0.1rem;
  color: var(--text-color-subtle);
  margin-bottom: 0.7rem;
}

.detailedLogsContainer,
.summarizedLogsContainer {
  width: calc(100%);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
}

.detailedLogsHeader,
.summarizedLogsHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid var(--light-border);
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.1);
}

.detailedLogsHeader > :nth-child(2),
.summarizedLogsHeader > :nth-child(1) {
  min-width: 7rem;
}

.detailedLogsHeader > :nth-child(3) {
  min-width: 7rem;
}

.detailedLogsHeader > :nth-child(4),
.summarizedLogsHeader > :nth-child(2) {
  display: flex;
  flex-grow: 1;
}

.detailedLogsHeaderTitle,
.summarizedLogsHeaderTitle,
.detailedLogsHeaderForSelections {
  text-align: left;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  color: var(--text-color-subtler);
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.detailedLogsHeaderTitleContent {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.detailedLogsBody,
.summarizedLogsBody {
  color: var(--text-color-subtle);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid var(--light-border);
  border-top: none;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  overflow-y: auto;
}

/* .customScroller::-webkit-scrollbar,
.dashboardMain::-webkit-scrollbar,
.detailedLogsBody::-webkit-scrollbar,
.summarizedLogsBody::-webkit-scrollbar { */
*::-webkit-scrollbar {
  width: 0.5rem;
}

/* .customScroller::-webkit-scrollbar-track,
.dashboardMain::-webkit-scrollbar-track,
.detailedLogsBody::-webkit-scrollbar-track,
.summarizedLogsBody::-webkit-scrollbar-track { */
*::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2rem
}

/* .customScroller::-webkit-scrollbar-thumb,
.dashboardMain::-webkit-scrollbar-thumb,
.detailedLogsBody::-webkit-scrollbar-thumb,
.summarizedLogsBody::-webkit-scrollbar-thumb { */
*::-webkit-scrollbar-thumb {
  background-color: var(--light-border);
  border-radius: 2rem
}


.detailedLogsBodyRow,
.summarizedLogsBodyRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--light-border);
}

.summarizedLogsBodyRow {
  max-height: 2.5rem;
}

.detailedLogsBody > :nth-child(1),
.summarizedLogsBody > :nth-child(1) {
  border-top: none;
}

.detailedLogsBody > :last-child,
.summarizedLogsBody > :last-child {
  border-bottom: 1px solid var(--light-border);
}

.detailedLogsBodyRow > :nth-child(2),
.summarizedLogsBodyRow > :nth-child(1) {
  min-width: 7rem;
  height: 100%;
}

.detailedLogsBodyRow > :nth-child(3) {
  min-width: 7rem;
  height: 100%;
}

.detailedLogsBodyRow > :nth-child(4),
.summarizedLogsBodyRow > :nth-child(2) {
  display: flex;
  flex-grow: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0rem 1rem;
}

.summarizedLogsHeader > :nth-child(1),
.summarizedLogsHeader > :nth-child(2),
.summarizedLogsBodyRow > :nth-child(1),
.summarizedLogsBodyRow > :nth-child(2) {
  flex-grow: 0;
  min-width: 6.5rem;
  max-width: 6.5rem;
  width: 6.5rem;
}

.summarizedLogsBodyRow > :nth-child(3) {
  flex-grow: 1;
}

.detailedLogsBodyTitle,
.summarizedLogsBodyTitle {
  padding: 0.5rem 1rem;
  text-align: left;
  box-sizing: border-box;
}

.detailedLogsBodyTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detailedLogsBodyRowButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-left: auto;
}

.dashboardControls {
  width: 100%;
  min-height: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  gap: 1rem;
}

.logsContainer {
  width: 100%;
  height: calc(70% - 8rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.timeLogifyStatusTxt {
  color: var(--text-color-subtle);
  font-size: 1.2rem;
}

.circularProgressWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  /* min-width: 12rem; */
  /* min-height: 12rem; */
  flex-shrink: 0;
}

.circularProgress {
  width: 100%;
  height: 100%;
}

.circularProgress:hover {
  cursor: pointer !important;
}

.circularProgressOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.circularProgressPercentage {
  font-size: 2rem;
  color: var(--text-color-subtle);
  font-weight: 500;
}

.circularProgressText {
  font-size: 1.5rem;
  color: var(--text-color-subtlerer);
}

.circularProgressStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.1rem;
  color: var(--text-color-subtle);
  margin-top: 0.6rem;
}

.circularProgressStatusTxt {
  transform: translateY(-0.1rem);
}

.dashboardControlsLeft {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  /* width: 100%; */
  height: 100%;
  overflow: hidden;
}

.playButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}

.pause {
  animation: pulse 1s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    filter: saturate(1.2);
    transform: scale(1.1);
  }
  50% {
    filter: saturate(1);
    transform: scale(1);
  }
  100% {
    filter: saturate(1.2);
    transform: scale(1.1);
  }
}

.weekSelector {
  display: flex;
  /* flex-grow: 1; */
  margin-right: auto;
  height: calc(100%);
  flex-direction: column;
  flex-shrink: 1;
}

.weekWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* flex-grow: 1; */
  padding-left: 1rem;
  min-height: 12rem;
}

.dayWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
  margin-right: 1rem;
  width: 5rem;
}

.dayWrapperNarrow {
  width: 2rem;
}

.dayBarWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 0.5rem;
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
}

.dayBar {
  height: 100%;
  width: 100%;
  background-color: var(--light-border);
  transition: background-color 0.5s;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  z-index: -1;
}

.dayBarSelected {
  background-color: var(--lighter-border);
  transition: filter 0.5s;
}

.dayBarFilledSelected {
  filter: brightness(1.2);
  transition: filter 0.5s;
}

.dayBarWrapper:hover > .dayBar {
  cursor: pointer;
  background-color: var(--lighter-border);
  transition: background-color 0.2s;
}

.dayBarWrapper:hover > .dayBarFilled {
  cursor: pointer;
  filter: brightness(1.2);
  transition: filter 0.2s;
}

.dayBarFilled {
  position: absolute;
  width: 100%;
  background-color: var(--accent-color);
}

.day {
  color: var(--text-color-subtlerer);
  font-size: 0.9rem;
}

.daySelected {
  color: white;
}

.weekSelectorHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  /* width: 100%; */
  /* overflow: hidden; */
}

.dateSelector {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid var(--lighter-border);
  margin-right: auto;
  margin-left: 1rem;
}

.dateSelectorBtn, 
.dateSelectorToday {
  color: var(--text-color-subtle);
  padding: 0.25rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dateSelectorBtn:hover,
.dateSelectorToday:hover {
  background-color: var(--light-border);
}

.dateSelectorBtn:active,
.dateSelectorToday:active {
  background-color: var(--lighter-border);
}

.dateSelectorBtn:focus,
.dateSelectorToday:focus {
  border: 1px solid var(--primary-color);
}

.dateSelector > :nth-child(1),
.dateSelector > :nth-child(2) {
  border-right: 1px solid var(--lighter-border);
}

.dateSelector > :nth-child(4),
.dateSelector > :nth-child(5) {
  border-left: 1px solid var(--lighter-border);
}

.dateSelector > :last-child {
  border-right: none;
}

.settingsMain {
  width: 80%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  overflow-y: scroll;
}

.settingsTitle {
  margin-left: 5rem;
  font-size: 2rem;
  gap: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.settingsSubtitle {
  margin-left: 5rem;
  font-size: 1.2rem;
  color: var(--text-color-subtle);
}

.settingsLbl {
  margin-left: 5rem;
  font-size: 1rem;
  color: var(--text-color-subtler);
  margin-bottom: 0.5rem;
}

.settingsInput {
  margin-left: 5rem;
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: var(--light-border);
  border: none;
  color: var(--text-color-subtle);
  min-width: 20rem;
}

.settingsInput::placeholder {
  color: var(--text-color-subtler);
  opacity: 0.5;
}

.settingsInput:focus {
  outline: none;
  border-bottom: 1px solid var(--primary-color);
}

.scheduleWrapper {
  margin: 1rem 0 1rem 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.scheduleDay {
  display: flex;
  width: 100%;
  gap: 5rem;
  font-size: 1.5rem;
  justify-content: space-between;
  color: var(--text-color-subtle);
}

.scheduleDayName,
.scheduleDayHours {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scheduleDayHours {
  width: 2rem;
}

.scheduleDayButtons {
  display: flex;
  gap: 1rem;
}

.scheduleDayButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
}

.scheduleSaveButtons {
  margin-top: 1rem;
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: flex-start;
}

.scheduleSaveButtonsInner{
  height: 0;
  width: 100%;
  transition: height 0.2s;
  display: flex;
  gap: 1rem;
  justify-content: start;
  align-items: flex-start;
  overflow: hidden;
}

.scheduleSaveButtonsInnerOpen {
  height: 100%;
}

.sidebarSetting {
  border-radius: 2rem;
  border: 1px solid var(--light-border);
  width: calc(100% - 5rem);
  padding: 2rem 2rem;

}

.underConstruction { 
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(204, 162, 47, 0.295);
  color: rgb(194, 150, 31);
  padding: 0.5rem;
  font-weight: 500;
}


.pulsating-circle {
  animation: pulse-status 1.5s infinite;
}

.logsContainerButtons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 0.5rem;
  padding: 0.5rem 0;
}

.feedbackContainer {
  margin-top: auto;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 4rem);
  border-radius: 1rem;
  background-color: rgba(var(--accent-color-rgb), 0.25);
  padding: 1rem;
  position: relative;
  transition: width 0.5s;
  min-height: 1rem;
  min-width: 1rem;
  align-self: flex-start;
  margin-left: 1rem;
}

.feedbackContainerClosed {
  width: 1rem;
  max-height: 1rem;
}

.feedbackCloseIcon {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  cursor: pointer;
}

.feedbackTitle {
  font-size: 1.5rem;
  text-align: left;
  width: 100%;
  margin-bottom: 1rem;
}

.feedbackInput {
  width: calc(100% - 2rem);
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--lighter-border);
  background-color: transparent;
  font-size: 1.2rem;
  resize: none;
  min-height: 5rem;
  font-size: 1rem;
  color: white;
  margin-bottom: 0.5rem;
}

.feedbackInput::placeholder {
  color: white;
  opacity: 0.5;
}

.buttonPopupMenu {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--background-color);
  border: 1px solid var(--light-border);
  z-index: 1000;
  margin: 0.5rem 0;
  box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.7);
  border-radius: 0.5rem;
}

.spinningRefreshIcon {
  animation: spin 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite reverse;
}

.logEntriesPopupLine:hover {
  background-color: var(--light-border);
}

.detailedLogsBodyRowInput {
  all: unset;
  color: var(--text-color-subtle);
  background-color: transparent;
  border: none;
  width: calc(100% - 0.2rem);
  border: 1px solid var(--text-color-subtlerer);
  border-radius: 0.3rem;
}

.detailedLogsBodyRowInput::placeholder {
  color: var(--text-color-subtler);
  opacity: 0.5;
}

.detailedLogsBodyRowInput:focus {
  outline: none;
}

.selectButton {
  margin: 0.5rem 0.2rem 0.5rem 0.7rem;
  min-height: 1.2rem;
  min-width: 1.2rem;
  max-height: 1.2rem;
  max-width: 1.2rem;
  border-radius: 0.3rem;
  border: 1px solid var(--light-border);
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: background-color 0.2s;
}

.selectButton:hover {
  cursor: pointer;
  background-color: var(--light-border);
}

.selectButtonChecked {
  background-color: var(--text-color-subtle);
  transition: background-color 0.2s;
}

.selectButtonChecked:hover {
  background-color: var(--text-color-subtler);
}

.detailedLogsHeaderForSelections {
  gap: 0.5rem;
}

.detailedLogsHeaderSelectAllText {
  color: rgba(var(--accent-color-rgb), 0.9);
  cursor: pointer;
}

.detailedLogsHeaderSelectAllText:hover {
  color: rgba(var(--accent-color-rgb), 1.2);
}

.detailedLogsHeaderForSelectionsButtons {
  display: flex;
  gap: 0.5rem;
  margin-right: 0.5rem;
}

.addTagCategory {
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  text-align: center;
  margin: 0.2rem 0.2rem;
  filter: saturate(0.8) brightness(0.9);
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.addTagCategory:hover {
  transition: all 0.2s;
  transform: scale(1.02);
  filter: none;
}

.addTagCategorySelected {
  filter: none;
}

.settingsTagCategories {
  min-width: 35%;
  /* overflow-y: ; */
  border: 1px solid var(--light-border);
  margin: 1rem 0 2rem 5rem;
  border-radius: 0.5rem;
}

.settingsRulesScroller,
.settingsTagCategoriesScroller {
  max-height: 9.75rem;
  overflow-y: auto;
  width: 100%;
  padding-bottom: 0.25rem;
}

.settingsRulesScroller {
  max-height: 10.75rem;
}

.settingsTagCategoriesFooter {
  border-top: 1px solid var(--light-border);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.settingsTagCategory {
  display: flex;
  align-items: center;
  padding: 0rem 0.5rem;
}

.settingsRule, .savedSettingsRule {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  color: var(--text-color-subtle);
  font-weight: 500;
}

.savedSettingsRule {
  color: white;
  border-radius: 0.5rem;
  margin: 0.25rem 0;
}

.settingsTagCategoryName {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-color-subtle);
  font-size: 1rem;
  font-weight: 500;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 1rem 0.5rem 0.5rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-right: 2rem;
}

.settingsTagCategoryInput {
  all: unset;
  color: var(--text-color-subtle);
  background-color: var(--background-color);
  border: none;
  width: calc(100% - 0.2rem);
  border-bottom: 1px solid var(--text-color-subtlerer);
  /* border-radius: 0.5rem; */
  text-align: left;
  padding: 0.5rem 1rem;
}

.settingsTagCategoryInput::placeholder {
  color: var(--text-color-subtler);
  opacity: 0.5;
}

.colorPickerPopup {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-radius: 0.5rem;
  max-width: 10.5rem;
  min-width: 10.5rem;
  width: 10.5rem;
  align-items: center;
  justify-content: center;
  justify-items: center;
  row-gap: 0.5rem;
  column-gap: 0rem;
  padding: 0.5rem 0.25rem;
}


.colorPicker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.colorPicker:hover {
  transform: scale(1.1);
  box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.5);
}


.settingsRules {
  min-width: 55%;
  border: 1px solid var(--light-border);
  margin: 1rem 0 2rem 5rem;
  border-radius: 0.5rem;
}

.ruleParamPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--light-border);
  background-color: var(--background-color);
}

.ruleParamSelection {
  display: flex;
  flex-grow: 1;
  cursor: pointer;
  border-radius: 0.5rem;
  width: calc(100% - 1rem);
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;
}

.ruleParamSelection:hover {
  background-color: var(--light-border);
}

.settingsRuleTxt {
  margin-right: 0.25rem;
}

.settingsRuleInput {
  all: unset;
  color: var(--text-color-subtle);
  background-color: var(--background-color);
  border: none;
  width: 10rem;
  border-bottom: 1px solid var(--text-color-subtlerer);
  text-align: left;
  padding: 0.25rem 0.5rem 0.125rem 0.25rem;
  margin-right: 0.5rem;
}

.settingsRuleInput::placeholder {
  color: var(--text-color-subtler);
  opacity: 0.5;
}

.detailedLogsRowTags {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.fullScreenPopup {
  z-index: 1000;
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5);
}

.jiraLogWorkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: var(--background-color);
  border: 1px solid var(--light-border);
  min-width: 30rem;
  /* box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.1); */
  animation: grow 0.2s;
}

@keyframes grow {
  0% {
    transform: scale(0.7) translateY(50%);
  }
  100% {
    transform: scale(1) translateX(0%);
  }
}

.jiraLogWorkTitle {
  font-size: 1.5rem;
  padding-left: 0.5rem;
}

.jiraLogWorkIssueRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  width: 100%;
}

.growOnHover {
  cursor: pointer;
  transition: all 0.2s;
}

.growOnHover:hover {
  filter: brightness(1.1);
}

a {
  color: inherit;
}

a:hover {
  color: var(--primary-color);
}

.growOnHover:hover .hoverPopup {
  visibility: visible;
  opacity: 1;
  bottom: 125%;
}
.growOnHover:hover .hoverPopdown {
  visibility: visible;
  opacity: 1;
  top: 125%
}

.growOnHover .hoverPopup,
.growOnHover .hoverPopdown {
  visibility: hidden;
  width: max-content;
  background-color: var(--background-color);
  text-align: center;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  position: fixed;
  bottom: 90%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0;
  transition: all 0.2s;
  font-size: 0.75rem;
  border: 1px solid var(--light-border);
}


.growOnHover .hoverPopdown {
  bottom: unset;
  top: 90%;
}

.growOnHover:hover .hoverPopupForWeekSelector {
  bottom: 75%;
}

.addTagHistoryIcon {
  cursor: pointer;
  position: absolute;
  right: 0.4rem;
  top: 0.4rem;
}

.tagAutoComplete {
  border: 1px solid transparent;
  padding: 0.2rem 0.7rem;
  position: absolute;
  left: 0;
  color: var(--text-color-subtler);
  pointer-events: none;
}

.tagAutoCompleteTab {
  border: 1px solid var(--text-color-subtlerer);
  color: var(--text-color-subtlerer);
  border-radius: 0.2rem;
  margin-left: 0.3rem;
  margin-bottom: 0.2rem;
  padding: 0 0.2rem;
  font-size: 0.7rem;
}

.plusCircleShowOnHover {
  display: none;
  justify-content: 'center';
  align-items: 'center';
}

.addTagCategory:hover .plusCircleShowOnHover {
  display: flex;
}

.keepMeSignedIn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1rem;
  color: var(--text-color-subtler);
  margin: 1rem 0;
}

.keepMeSignedIn .checkBox {
  transform: translateY(0.05rem);
  
}

/* Hide the default checkbox */
.keepMeSignedIn input[type="checkbox"] {
  appearance: none;  /* Removes default styles */
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

/* Custom checkbox container */
.keepMeSignedIn .custom-checkbox {
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--text-color-subtler);
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  transform: translateY(0.05rem);
}

/* Checkmark when the checkbox is checked */
.keepMeSignedIn input[type="checkbox"]:checked + .custom-checkbox::before {
  content: "✔";
  font-size: 1rem;
  color: white;
  font-weight: bold;
}

/* Background change when checked */
.keepMeSignedIn input[type="checkbox"]:checked + .custom-checkbox {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
}

/* Border color on hover and focus */
.keepMeSignedIn input[type="checkbox"]:hover + .custom-checkbox,
.keepMeSignedIn input[type="checkbox"]:focus + .custom-checkbox {
  border-color: var(--accent-color);
}

.checkmarkLbl {
  cursor: pointer;
}

.shake {
  animation: shake 0.3s;
}

@keyframes shake {
  0% { transform: rotate(0) translateX(0); }
  20% { transform: rotate(1deg) translateX(-0.1rem); }
  40% { transform: rotate(-2deg) translateX(0.1rem); }
  60% { transform: rotate(1deg) translateX(-0.1rem); }
  80% { transform: rotate(-2deg) translateX(0.1rem); }
  100% { transform: rotate(0) translateX(0); }
}

.wiggle {
  animation: wiggle 0.2s;
}

@keyframes wiggle {
  0% { transform: rotate(0) }
  20% { transform: rotate(0.5deg) }
  40% { transform: rotate(-1deg) }
  60% { transform: rotate(0.5deg) }
  80% { transform: rotate(-1deg) }
  100% { transform: rotate(0) }
}